
<template>
  <div>
    <div class="container-fluid px-4">
      <div class="row">
        <tab-group
          v-if="tabsLists.includes($route.name) === true"
          :routes="routes"
        ></tab-group>
        <div class="col-12 pl-0">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {permissionMixin} from "@/mixins/permissionMixins";
import TabGroup from "@/components/common/TabGroup.vue";

export default {
  name: "VesselAudit",
  mixins : [permissionMixin],
  data() {
    return {
      tabsLists : [
        'VesselList',
        'VesselAuditDue',
        'ObjectiveEvidenceList',
      ],
    }
  },
  components: {TabGroup},
  computed : {
    routes() {
      let routes = [];

      if (this.isAllowed('vessel-ia.appointments.audit-records.menu') === true) {
        routes.push(
          {
            name : 'VesselList',
            text : 'Vessel Audit Records'
          });
      }

      if (this.isAllowed('vessel-ia.appointments.objective-evidence.menu') === true) {
        routes.push(
          {
            name : 'ObjectiveEvidenceList',
            text : 'Vessel Objective Evidence'
          });
      }

      if (this.isAllowed('vessel-ia.appointments.vessel-audit-due.menu') === true) {
        routes.push(
          {
            name : 'VesselAuditDue',
            text : 'Vessel Audit Due'
          });
      }

      return routes;
    }
  }
}
</script>



